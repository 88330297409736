var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basket"},[_c('div',{staticClass:"basketBox"},[_c('div',{staticClass:"bTitle"},[_c('router-link',{staticClass:"btn nav-button",attrs:{"to":{ name: 'toccaVinoProducts' }}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_vm._m(0)],1),_vm._l((_vm.basket),function(item,i){return _c('div',{key:i + 'item',staticClass:"basketProd"},[_c('div',{staticClass:"basketLeft"},[_c('div',{staticClass:"prodName"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"prodDescription"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-img',{staticClass:"grey lighten-2 prodMedia imgBasket",attrs:{"src":item.media[0],"aspect-ratio":16 / 9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"actionIcon",on:{"click":function($event){return _vm.addItem(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-plus-box")])]}}],null,true)},[_c('span',[_vm._v("add one")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"actionIcon",on:{"click":function($event){return _vm.removeItem(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-minus-box")])]}}],null,true)},[_c('span',[_vm._v("remove one")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"actionIcon",on:{"click":function($event){return _vm.removeFromBasket(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("remove item")])])],1),_c('div',[_vm._v("quantity: "+_vm._s(item.itemsNumber))])],1),_c('div',{staticClass:"prodPrice"},[_vm._v(_vm._s(item.price)+"€")])])})],2),_c('div',{staticClass:"checkoutBox"},[_c('v-btn',{attrs:{"color":"primary","rounded":"","dark":"","depressed":""},on:{"click":_vm.clearBasket}},[_vm._v(" clear basket "),_c('v-icon',{staticClass:"actionIcon"},[_vm._v("mdi-basket-remove")])],1),_c('div',{staticClass:"checkTop"},[_c('span',{staticClass:"prodName"},[_vm._v("Total items: ")]),_c('span',{staticClass:"prodName"},[_vm._v(_vm._s(_vm.totalItems))])]),_c('div',{staticClass:"checkTop"},[_c('span',{staticClass:"prodName"},[_vm._v("Total: ")]),_c('span',{staticClass:"prodName"},[_vm._v(_vm._s(_vm.totalPrice)+" €")])]),_c('router-link',{staticClass:"btn nav-button",attrs:{"to":{ name: 'toccaVinoPayment' }}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","dark":"","depressed":""}},[_vm._v(" checkout ")])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("basket")])])}]

export { render, staticRenderFns }