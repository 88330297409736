var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myListsBox"},[_c('div',{staticClass:"listHeader"},[_c('router-link',{staticClass:"btn nav-button",attrs:{"to":{ name: 'toccaVinoHome' }}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('h3',[_vm._v("my lists editor")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteEventConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('h2',[_vm._v("Events")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersEvents,"items":_vm.events},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"iconLink",attrs:{"to":{ name: 'toccaVinoEditEvent', params: { id: item.id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteEvent(item.id, item)}}},[_vm._v(" mdi-delete ")]),_c('a',{staticClass:"iconLink",attrs:{"href":("https://www.google.com/maps/@" + (item.location.latitude) + "," + (item.location.longitude) + ",15z")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-google-maps ")])],1)]}},{key:"item.media",fn:function(ref){
var item = ref.item;
return [(item.media)?_c('v-img',{staticClass:"grey lighten-2 myImageList",attrs:{"src":item.media[0],"aspect-ratio":16 / 9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}}],null,true)}),_c('h2',[_vm._v("Cellars")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCellars,"items":_vm.cellars},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"iconLink",attrs:{"to":{
          name: 'toccaVinoEditEvent',
          params: { id: item.id, cellar: true },
        }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteCellar(item.id, item)}}},[_vm._v(" mdi-delete ")]),_c('a',{staticClass:"iconLink",attrs:{"href":("https://www.google.com/maps/@" + (item.location.latitude) + "," + (item.location.longitude) + ",15z")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-google-maps ")])],1)]}},{key:"item.media",fn:function(ref){
        var item = ref.item;
return [(item.media)?_c('v-img',{staticClass:"grey lighten-2 myImageList",attrs:{"src":item.media[0],"aspect-ratio":16 / 9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }