<template>
  <v-file-input
    v-model="files"
    color="deep-purple accent-4"
    counter
    label="File input"
    multiple
    placeholder="Select your files"
    prepend-icon="mdi-paperclip"
    outlined
    :show-size="1000"
    @change="upload"
  >
    <template v-slot:selection="{ index, text }">
      <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="overline grey--text text--darken-3 mx-2"
      >
        +{{ files.length - 2 }} File(s)
      </span>
    </template>
  </v-file-input>
</template>


<script>
export default {
  data: () => ({
    files: [],
  }),
  methods: {
    upload() {
      this.$emit("upload-file", this.files);
    },
  },
};
</script>
